import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueParticles from 'vue-particles'
import dataV from '@jiaminghi/data-view'
import VCalendar from "v-calendar";
import date from '@/utils/Date'
import VueI18n from 'vue-i18n'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: 'Vh0A9W0VMeG6jrVbt6v4koCGECRVlDDe' })
Vue.use(VueI18n) // 通过插件的形式挂载
const i18n = new VueI18n({
  locale: sessionStorage.getItem('langSet') || 'cn', //缓存获取用户中英文选择，没有则默认中文 
  formatFallbackMessages: true,
  messages: {
    'cn': require('./static/cn'),   // 中文语言包   
    'en': require('./static/en')    // 英文语言包  
  }
}
)

Vue.prototype.$date = date
import timeLine from "as-time-line";
import "as-time-line/lib/timeline.css";
Vue.use(VueParticles)
Vue.use(timeLine);
Vue.use(dataV)
Vue.use(VCalendar);
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
Vue.prototype.$axios = axios
Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  data: {
    Bus: new Vue()
  },
  render: function (h) { return h(App) }
}).$mount('#app')
