import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tableTabs: [
      {
        icon: require("@/assets/zy.png"),
        title: "主控面板",
        name: "a",
        path: "/panel/Panel/LeftPanel",
      }
    ],

    r_data: [{
      icon: require('@/assets/sb.png'),
      title: '设备管理'
    }
    ],
    activeName: "a",
    computedWidth: "100%",
    window_num: [1, 4, 9, 16],
    windowCount: [
      {
        count: 1,
        url: "",
        label: ""
      },
    ],
    count: 0,
    window_url: "",
    ischangeUrl: false,
    zindex: 0,
  },
  mutations: {
    initTab(state,val){
      if(val){
        state.tableTabs = val
      }
    },
    initZindex(state,val){
      if(val){
        state.zindex = val
      }
    },
    language(state,val){
      state.tableTabs[0].title = val[0]
      state.r_data[0].title = val[1]
    },
    initIndex(state, val) {
      state.zindex = val
    },
    changeIndex(state, val) {
      state.zindex = val
    },
    computedWidth(state, val) {
      let n = 1;
      while (n * n != val) {
        n++;
        if (n * n > val) {
          return;
        }
      }
      state.computedWidth = 100 / n + "%";
    },
    changeWidow(state, val) {
      for (let i = state.windowCount.length; i < val; i++) {
        if (i < val) {
          state.windowCount.push({
            count: i,
            url: "",
          });
        }
      }
      state.windowCount.length = val;
    },
    activeName(state, val) {
      state.activeName = val;
    },
    addWindow(state, val) {
      state.window_url = val
      state.windowCount.forEach((item) => {
        if (item.url) {
          if (item.label == val.label) {
            return false
          } else {
            item.url = val
            state.count = Number(item.count) - 1;
            state.ischangeUrl = true
          }
        } else {
          item.url = val
        }
        return;
      });
    },
    init(state) {
      state.windowCount[
        {
          count: 1,
          url: "",
        }
      ];
      state.count = 0
    },
    check(state, val) {
      state.count = val.index
      state.windowCount[val.index].url = val.url
    },
    
  },
  actions: {
    del(context, newName) {
      setTimeout(() => {
        context.state.tableTabs.forEach((item, index) => {
          if (item.title == newName.val.title) {
            context.state.tableTabs.splice(index, 1)
            context.state.zindex = index - 1
          }
        })
      }, 100);
    },
    changeNameAsync(context, newName) {
      let isok = true
      context.state.tableTabs.forEach((val,i)=> {
        if (val.title == newName.val.title) {
          isok = false
          context.state.zindex = i
        }
      })
      if (isok) {
        context.state.tableTabs.push(newName.val)
        setTimeout(() => {
          context.state.tableTabs.forEach((item, index) => {
            if (item.name == newName.val.name) {
              context.state.zindex = index
            }
          })
        }, 20);
      }

    },

  },
  getters: {},
  modules: {},
});
