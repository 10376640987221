module.exports = {
    language: {
        name: '中文',
    },
    panel: {
        LeftPanel:"主控面板",
        play: '实时视频',
        playback: '录像回放',
        playcenter: '中心回放',
        ai:"AI报警",
        equipment:"设备管理",
        node:"28181节点设置",
        serve:"28181中心管理",
        Baidu:"在线地图",
        register:"注册信息",
        police:"AI报警",
        status:"服务状态",
        setting:"系统设置",
        plan:"录像计划",
        user:"用户管理",
    },
    tab:{
        now:"当前语言",
        change:"切换",
        admin:"管理员",
        out:"退出登录",
        list:"列表",
        grop:"分组",
        none:"暂无数据",
        empty:"暂无节点",
        choose_device:"请选择设备",
        choose_data:"选择日期",
        recording:"设备录像",
        Cloud:"云录像",
        del:"删除",
        update:"编辑",
        searching:"正在搜索",
        content:"请输入内容"
    },
    play:{
        show:"展示控件",
        filter:"请输入关键字进行过滤",
        screen:"画面",
        stop:"关闭",
        stopall:"全部关闭",
        search:"搜索",
        slider:"速度",
    },
    table:{
        devices:"设备列表",
        nat:"NAT设备",
        area:"添加区域",
        device:"添加设备",
        name:"通道名",
        ip:"IP",
        num:"通道号",
        alias:"通道别名",
        notes:"通道备注",
        type:"通道类型",
        log:"经度",
        lat:"纬度",
        operate:"操作",
        all:"所有设备",
        device_search:"设备搜索",
        increase:"添加",
        sort:"排序",
        device_type:"设备类型",
        device_name:"设备名称",
        device_ip:"IP",
        device_port:"端口",
        device_mac:"MAC",
        manage:"添加到管理",
        keywords:"关键字",
        serial:"序列号",
        address:"IP地址",
        status_manager:"管理状态",
        presence:"在线状态",
        region:"所属区域",
        edit_region:"编辑区域",
        unmanaged:"未管理",
        managed:"已管理",
        on_line:"在线",
        offline:"离线",
    },
    dialog:{
        zone:"新建区域",
        last:"上一级区域",
        action:"活动区域",
        notes:"备注",
        cancel:"取 消",
        ok:"确 定",
        add_device:"添加设备",
        fport:"流端口",
        user:"用户名",
        pwd:"密码",
        edit:"编辑通道",
        default_stream:"默认码流",
        choose_stream:"请选择码流",
        alias:"别名",
        subcode_stream:"子码流",
        main_stream:"主码流",
        adaptive_bitstream:"自适应码流",
        change:"切换语言",
        choose_lau:"请选择语言",
        isdel:"是否删除节点",
        prompt:"提示",
        del_ok:"删除成功",
        del_cal:"删除失败",
        save_ok:"保存成功",
        save_cal:"保存失败",
        repeat:"请检查ID是否存在且ID不能为空",
        equal:"通道ID长度必须为20位",
        del_error:"已取消删除",
        manger_error:"该设备已经被管理，无需重复管理",
        add_suss:"添加成功",
        out:"确定退出登录吗",
        out_ok:"退出成功",
        update_ok:"修改成功",
        update_error:"修改失败",
        choose_deviced:"请先选择通道!",
        add_error:"添加失败",
        fill_ip:"请填写IP地址！",
        upload:"录像下载",
        add_task:"添加任务",
        del_task:"删除任务",
        clear_task:"清空任务",
        open_list:"打开目录",
    },
    newadd:{
        son:"子码流",
        host:"主码流",
        catch_error:"搜索失败",
        cease:"停止",
        start:"开始",
        edit_device:"编辑",
        edit_area:"编辑",
        login:"登录",
        rember:"记住信息",
        open:"打开视频",
        wifi_error:"网络错误",
        add_grop:"添加分组",
        del_grop:"删除分组",
        del_device:"删除通道",
        add_device:"导入通道",
        grop_name:"分组名称",
        grop_pla:"请输入分组名称",
        infotip:"信息提示",
        tiptitle_grop:"确定删除改分组吗?",
        tiptitle_grop:"确定删除当前所选通道?",
        choose_add:"导入选择",
        device:"设备",
        fare:"通道",
        cacel:"取消",
        ok:"确定",
        save:"保存",
        belong:"设备归属",
        admin:"行政区域",
        district:"警区",
        address:"安装地址",
        log:"经度",
        lag:"纬度",
        tip:"提示",
        add_center:"添加中心",
        update_center:"编辑中心",
        del_center:"删除中心",
        restart_center:"重启服务",
        choose_list:"选择列表",
        input_pla:"请输入内容",
        typename:"类型",
        lastlist:"上行列表",
        addlastlist:"添加上行节点",
        dellastlist:"删除上行节点",
        name:"名称",
        start:"播放",
        ip:"上层平台IP",
        port:"上层平台端口",
        cmdmode:"信令通讯模式",
        datamode:"视频通讯模式",
        serverid:"上层平台ID",
        deviceid:"本地平台ID",
        password:"注册密码",
        domain:"域",
        deviceip:"本地平台IP",
        del_noserve:"确定删除所选的28181中心吗",
        restarttitle:"确定重启服务吗",
        addlast:"是否添加到上行节点",
        dellast:"确定删除所选的上行节点吗",
        name_pla:"请输入名称",
        ip_pla:"请输入上层平台IP",
        port_pla:"请输入上层平台端口",
        select_pla:"请选择",
        serverid_pla:"请输入上层平台ID",
        deviceid_pla:"请输入本地平台ID",
        password_pla:"请输入注册密码",
        domain_pla:"请输入域",
        deviceip_pla:"请输入本地平台IP",
        restart_ok:"重启成功",
        restart_error:"重启失败",
        export:"导出"
    }
}