export default {
  // 返回年月日
  getTime() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear(); //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    // var time =`${year}${month < 10 ? month : '0'+ month}${day < 10 ? "0" + day : day}`
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    let time = `${year}${month}${day}`;
    return time;
  },
  // 中国标准时间转为 yyyy-mm-dd hh-mm-ss
  getDate(date) {
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    let M = date.getMinutes()
    M = M < 10 ? ('0' + M) : M
    let s = date.getSeconds()
    s = s < 10 ? ('0' + s) : s
    let dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
    return dateTime
  },
  getDateTime() {
    // 创建一个新的 Date 对象
    let currentDate = new Date();
    // 获取当前时间的小时、分钟和秒
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();
    // 格式化时间，确保小时、分钟和秒始终有两位数
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    // 构建表示当前时间的字符串
    let currentTime = hours + ":" + minutes + ":" + seconds;
    return currentTime

  }
};

