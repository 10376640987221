module.exports = {
    language: {
        name: 'English'
    },
    panel: {
        LeftPanel:"Control Panel",
        play: 'Live View',
        playback: 'Remote Playback',
        playcenter: 'Center Playback',
        ai:"AI Alarm",
        equipment:"Device Management",
        node:"SIP Node Setup",
        serve:"SIP Center Management",
        Baidu:"online_map",
        register:"Registration Information ",
        police:"AI Alarm",
        status:"Health Monitoring ",
        setting:"System Configuration ",
        plan:"录像计划",
        user:"用户管理",
    },
    tab:{
        now:"Language",
        change:"Switch",
        admin:"Administrator",
        out:"Log out",
        list:"Device List",
        grop:"Group",
        none:"No Data Available Now",
        empty:"No Data",
        choose_device:"Select Device",
        choose_data:"Select Date",
        recording:"Device recording",
        Cloud:"Cloud/Center recording",
        del:"Delete",
        update:"Edit",
        searching:"Searching",
        content:"Please input content"
    },
    play:{
        show:"Display Control",
        filter:"Please enter your search  keywords",
        screen:"",
        stop:"Close",
        stopall:"Close All",
        search:"Search",
        slider:"Speed"
    },
    table:{
        devices:"Device List",
        nat:"NAT Device",
        area:"Add Area",
        device:"Add Device",
        name:"Channel Name",
        ip:"IP",
        num:"Channel No",
        alias:"Channel Alias",
        notes:"Channel Remarks",
        type:"Channel Type",
        log:"Longitude",
        lat:"Latitude",
        operate:"Operate",
        all:"All",
        device_search:"Search",
        increase:"Add",
        sort:"Sort",
        device_type:"Device Category",
        device_name:"Device Name",
        device_ip:"IP",
        device_port:"Port",
        device_mac:"MAC",
        manage:"Add To Management",
        keywords:"keywords",
        serial:"Serial No.",
        address:"IP Address",
        status_manager:"Management status",
        presence:"Online Status",
        region:"Belonging Area",
        edit_region:"Edit Area",
        unmanaged:"Unmanaged",
        managed:"managed",
        on_line:"Online",
        offline:"offline"
    },
    dialog:{
        zone:"New Area",
        last:"Upper Level Area",
        action:"Area Name",
        notes:"Remark",
        cancel:"cancel",
        ok:"Confirm",
        add_device:"Add Device",
        fport:"Stream Port",
        user:"User Name",
        pwd:"Password",
        edit:"Edit Channel",
        default_stream:"Default Stream",
        choose_stream:"Please select a stream",
        alias:"Alias",
        subcode_stream:"Sub Stream",
        main_stream:"Main Stream",
        adaptive_bitstream:"Auto Adaptive Stream",
        change:"Change Language",
        choose_lau:"Language",
        isdel:"Delete the selected node ",
        prompt:"Attention",
        del_ok:"Delete Successfully ",
        del_error:"Deletion operation has been cancelled.",
        manger_error:"The device has been managed.",
        add_suss:"Add successfully",
        out:"Are you sure to log out",
        out_ok:"Exit successfully",
        update_ok:"Modify successfully",
        update_error:"Fail to modify",
        choose_deviced:"Please select a channel first!",
        add_error:"Fail to add",
        fill_ip:"Please input IP address!",
        upload:"录像下载",
        add_task:"添加任务",
        del_task:"删除任务",
        clear_task:"清空任务",
        open_list:"打开目录",
    },
    newadd:{
        son:"Sub Stream",
        host:"Main Stream",
        catch_error:"Search Failed",
        cease:"Stop",
        start:"Play",
        edit_device:"Edit",
        edit_area:"Edit",
        login:"Login",
        rember:"Remember Information",
        open:"Open",
        wifi_error:"Network Error",
        add_grop:"Add Group",
        del_grop:"Delete Group",
        del_device:"Delete Video Channel",
        add_device:"Import Video Channel",
        grop_name:"Group Name",
        grop_pla:"Please input group name.",
        infotip:"Reminder",
        tiptitle_grop:"Delete this selected group?",
        tiptitle_grop:"Delete the selected video channel?",
        choose_add:"Import Selection",
        device:"Device",
        fare:"Channel",
        cacel:"Cancel",
        ok:"Confirm",
        save:"Save",
        belong:"Device Belonging",
        admin:"Administrative Region",
        district:"Police District",
        address:"Camera Installation location",
        log:"Longitude",
        lag:"Latitude",
        tip:"Remark",
        add_center:"Add Center",
        update_center:"Edit Center",
        del_center:"Delete Center",
        restart_center:"Restart Server",
        choose_list:"Select List",
        input_pla:"Please input keywords",
        typename:"Tpye",
        name:"Name",
        start:"Enable",
        ip:"Upper Platform IP",
        port:"Upper Platform Port",
        cmdmode:"Signaling Communication",
        datamode:"Video Communication",
        serverid:"Upper Platform ID",
        deviceid:"Local Platform ID",
        password:"Registration Password",
        domain:"Domain",
        deviceip:"Local Platform IP",
        del_noserve:"Delete the selected SIP centers?",
        restarttitle:"Are you sure to restart server?",
        addlast:"Add to the uploading node?",
        dellast:"Confirm to delete the selected uploading node? ",
        name_pla:"Please input the name.",
        ip_pla:"Please input the upper platform IP",
        port_pla:"Please input the upper platform port.",
        select_pla:"Please select.",
        serverid_pla:"Please input the upper platform ID",
        deviceid_pla:"Please input the local platform ID",
        password_pla:"Please input registration password",
        domain_pla:"Please input domain",
        deviceip_pla:"Please input local platform IP",
        restart_ok:"Restart sucessfully!",
        restart_error:"Restrat failed!",
        export:"导出"
    }
}
