import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "登录",
      requiresAuth: false,
    },
    component: function () {
      return import("@/views/HomeView/HomeView.vue");
    },
  },
  {
    path: "/panel",
    name: "panel",
    redirect: "/panel/Panel/LeftPanel",
    meta: {
      title: "面板",
      requiresAuth: true,
    },
    component: function () {
      return import("@/views/PanelView/index.vue");
    },
    children: [
      {
        path: "Panel/LeftPanel",
        name: "LeftPanel",
        meta: {
          title: "左侧面板",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/LeftPanel/LeftPanel.vue");
        },
      },
      {
        path: "/play",
        name: "test",
        meta: {
          title: "test",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/test/index.vue");
        },
      },
      {
        path: "/playback",
        name: "recording",
        meta: {
          title: "recording",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Recording/Recording.vue");
        },
      },
      {
        path: "/equipment",
        name: "equipment",
        meta: {
          title: "equipment",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/equipment/tab.vue");
        },
      },
      {
        path: "/devices",
        name: "devices",
        meta: {
          title: "devices",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/equipment/Devices/equipment.vue");
        },
      },
      {
        path: "/playcenter",
        name: "playcenter",
        meta: {
          title: "playcenter",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Center/Center.vue");
        },
      },
      {
        path: "/nat",
        name: "nat",
        meta: {
          title: "nat",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/equipment/Nat/Nat.vue");
        },
      },
      {
        path: "/node",
        name: "node",
        meta: {
          title: "node",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Node/node.vue");
        },
      },
      {
        path: "/serve",
        name: "serve",
        meta: {
          title: "serve",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Serve/serve.vue");
        },
      },
      {
        path: "/baidu",
        name: "baidu",
        meta: {
          title: "serve",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Baidu/Baidu.vue");
        },
      },
      {
        path: "/ai_alarm",
        name: "ai_alarm",
        meta: {
          title: "ai_alarm",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Police/police.vue");
        },
      },
      {
        path: "/status",
        name: "status",
        meta: {
          title: "status",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Status/status.vue");
        },
      },
      {
        path: "/setting",
        name: "setting",
        meta: {
          title: "setting",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/Setting/setting.vue");
        },
        children: [{
          path: "/port",
          name: "port",
          meta: {
            title: "port",
            requiresAuth: true,
            keepAlive: true
          },
          component: function () {
            return import("@/views/PanelView/Setting/port/port.vue");
          },
        }, {
          path: "/Streaming",
          name: "Streaming",
          meta: {
            title: "Streaming",
            requiresAuth: true,
            keepAlive: true
          },
          component: function () {
            return import("@/views/PanelView/Setting/Streaming/Streaming.vue");
          },
        }, {
          path: "/videoserve",
          name: "videoserve",
          meta: {
            title: "videoserve",
            requiresAuth: true,
            keepAlive: true
          },
          component: function () {
            return import("@/views/PanelView/Setting/VideoService/VideoService.vue");
          },
        }, {
          path: "/disk",
          name: "disk",
          meta: {
            title: "disk",
            requiresAuth: true,
            keepAlive: true
          },
          component: function () {
            return import("@/views/PanelView/Setting/Disk/disk.vue");
          },
        }]
      },
      {
        path: "/plan",
        name: "plan",
        meta: {
          title: "plan",
          requiresAuth: true,
          keepAlive: true
        },
        component: function () {
          return import("@/views/PanelView/VideoPlan/videoplan.vue");
        },
      },
      {
        path: "/user",
        name: "user",
        meta: {
          title: "user",
          requiresAuth: true,
          keepAlive: true
        },
        children: [{
          path: "/role",
          name: "role",
          meta: {
            title: "role",
            requiresAuth: true,
            keepAlive: true
          },
          component: function () {
            return import("@/views/PanelView/User/role/role.vue");
          },
        }, {
          path: "/administer",
          name: "administer",
          meta: {
            title: "administer",
            requiresAuth: true,
            keepAlive: true
          },
          component: function () {
            return import("@/views/PanelView/User/admin/admin.vue");
          },
        }],
        component: function () {
          return import("@/views/PanelView/User/user.vue");
        },
      },
    ],
  },
];



const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let token = window.sessionStorage.getItem('token');
  if (token) {
    next();
  } else {
    next();
    // if (to.path === '/') {
    //   next();
    // } else {
    //   next('/');
    // }
  }
});



export default router;
